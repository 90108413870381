import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Ok, to be honest, the wild east isn't so wild here (except the enduro
track maybe). Coming from the german border we are already in the altitude
and park the Trailguide mobile directly at the top of the Klinovec mountain.
The surrounding hills and valleys are completely covered by forest as far as
the eye can see.`}</p>
    <br />
    <p>{`Klinovec has a DH track for many years but started to built up a wide
network of singletracks with some of the longest descents in Europe.
Without a warmup we start with the easiest trail. Five trails are signed.
Three of them Flowtrails. The Azur is the easiest and with 10km also longest
Trail. Without using our brakes we float down the mountain, warm up our
stiff limbs and ride and ride and ride. It just won't end.`}</p>
    <br />
    <p>{`The trail is perfect for beginners, to get a feeling for the bike, without
hanging on the brakes or dealing with difficult obstacles.`}</p>
    <TrailguidePlugin content="lat=50.3859&lng=12.9587" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=14,50.3859,12.9587"
        }}>{`web-app`}</a></p>
    </p>
    <p>{`From the bottom a surprisingly fast chairlift brings us up the almost 500vm
again.`}</p>
    <br />
    <p>{`The other two flowtrails Rubin and Baron suits us much more, and are pure
fun with its countless corners and compressions. We become faster and faster,
hover over smooth waves, jump and double obstacles, fly out of the corners,
drift over the gravel and are caught again in the berms. Again.`}</p>
    <br />
    <p>{`But enough with all the speed and flow. Now comes the DH track. Very fast
with many berms and tables, some artificial rock gardens and only a short
natural part over some steps and roots, the track definitely brings our
trail bikes to a limit. Not for nothing the track is part of several
Downhill Cups. Probably much more fun with a proper DH bike, so we move
on to the Enduro track.`}</p>
    <br />
    <p>{`I can tell it directly, this is our favourite
descent! A natural style singletrack with loads of roots and rocks. Not too
technical, and often with different line choices. So after a couple of runs
it becomes smoother and smoother. We know where to be light over the
obstacles, and where to find grip in the soft forest floor. Challenging,
but awesome trail, once again with a very nice rythm only mother nature
can create.`}</p>
    <br />
    <p>{`To sum it up, here you have the whole specter of trails, a very good
elevation and a fast chairlift. Other spots like the Trailcenter Rabenberg
are close, so it is perfect to combine.`}</p>
    <br />
    <br />
    <h4>{`Azur`}</h4>
    <p>{`Easy Azur trail for beginners and a good warm up lap Over 10km of descent!
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3317"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/klinovec_5.jpg" mdxType="Image" />
    <br />
    <h4>{`Rubin`}</h4>
    <p>{`Pure fun with its countless corners and compressions.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3316"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/klinovec_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Baron`}</h4>
    <p>{`Martin, the head of the trail builders can not only built great trails.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3315"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/klinovec_6.jpg" mdxType="Image" />
    <br />
    <h4>{`Rubin`}</h4>
    <p>{`Fast section with a good view in the Rubin trail.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3316"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/klinovec_2.jpg" mdxType="Image" />
    <br />
    <h4>{`Enduro`}</h4>
    <p>{`Max Hartenstern shows how to ride this steep section.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3314"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/klinovec_4.jpg" mdxType="Image" />
    <br />
    <h4>{`Downhill`}</h4>
    <p>{`The DH track really earns its name. Rough and fast. Not for nothing
it is part of the European Downhill Cup.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3313"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/klinovec_3.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      